import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
// import { graphql } from 'gatsby'
import styles from "./formContent.module.css"
import "../styles/main.css";
import ReCAPTCHA from "react-google-recaptcha";


const recaptchaRef = React.createRef();

function submitForm(e) {
    e.preventDefault();
    let contactNumber = document.getElementById('contactNumber').value;
    let contactErrorMsg = document.getElementById('contactErrorMsg');
    let emailId = document.getElementById('email').value;
    let emailErrorMsg = document.getElementById('emailErrorMsg');
    let domain_Name = emailId.substring(emailId.indexOf('@') + 1);
    contactErrorMsg.innerHTML = "";
    emailErrorMsg.innerHTML = "";
    //let re = '[a-zA-Z_\\.-]+@((hotmail)|(yahoo)|(gmail)|(rediff)|(msn)|(rediffmail)|(outlook))\\.[a-z]{2,4}';

    if (domain_Name.indexOf('aol') > -1 || domain_Name.indexOf('hotmail') > -1 || domain_Name.indexOf('yahoo') > -1 || domain_Name.indexOf('gmail') > -1 || domain_Name.indexOf('rediff') > -1 || domain_Name.indexOf('msn') > -1 || domain_Name.indexOf('rediffmail') > -1 || domain_Name.indexOf('outlook') > -1) {
        emailErrorMsg.style.color = 'red';
        emailErrorMsg.innerHTML = 'Please Enter Business Email Address';
        return false;
    }

    let filterContactRegex = /^[\s()+-]*([0-9][\s()+-]*){10,15}$/;

    if (!(contactNumber.match(filterContactRegex))) {
        contactErrorMsg.style.color = 'red';
        contactErrorMsg.innerHTML = 'Please Enter Valid Contact Number';
        return false;
    }


    const recaptchaValue = recaptchaRef.current.getValue();
    let message = document.getElementById('message');
    message.innerHTML = "";

    if (recaptchaValue === '' || recaptchaValue === undefined || recaptchaValue === null) {
        message.innerHTML = 'Please select the captcha';
        message.style.color = "red";
        message.style.display = "block";
        return false;
    }

    let form = e.target;
    let formData = new FormData(form);
    let body = {};
    formData.forEach((value, key) => { body[key] = value });
    var json = JSON.stringify(body);
    var xhr = new XMLHttpRequest();
    xhr.open("POST", "api/contact", false);
    xhr.onreadystatechange = () => {
        if (xhr.readyState === 4 && xhr.status === 200) {
            message.innerHTML = "Thank you. We will get back to you soon";
            message.style.color = "green";
            message.style.display = "block";
            form.reset();
            setTimeout(() => message.style.display = "none", 2500);
        } else { // TODO: Pending status is also treated as failed. To fix the same.
            message.innerHTML = "Some Error Occured";
            message.style.color = "red";
            message.style.display = "block";
        }
    }

    xhr.setRequestHeader("content-type", "application/json");
    xhr.send(json);

    recaptchaRef.current.reset();

    if (typeof document === 'undefined') return null;
}

function checkEmail() {
    let filter = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    let emailId = document.getElementById("email").value;
    let emailErrorMsg = document.getElementById('emailErrorMsg');
    let domain_Name = emailId.substring(emailId.indexOf("@") + 1);

    if (
        !(emailId.match(filter)) ||
        domain_Name.indexOf("hotmail") > -1 ||
        domain_Name.indexOf("yahoo") > -1 ||
        domain_Name.indexOf("gmail") > -1 ||
        domain_Name.indexOf("rediff") > -1 ||
        domain_Name.indexOf("msn") > -1 ||
        domain_Name.indexOf("rediffmail") > -1 ||
        domain_Name.indexOf("outlook") > -1 ||
        domain_Name.indexOf("aol") > -1
    ) {
        emailErrorMsg.style.color = "red";
        emailErrorMsg.innerHTML = "Please Enter Business Email Address";

        return false;
    }

    emailErrorMsg.innerHTML = '';
}


//minLength="10" maxLength="10" pattern="^[0-9]{10}$"

export default function formContent() {
    return (
        <div>
            <div className="container ">
                <div className="row">
                    < div className="col-lg-12 flex" >
                        < form className={`form-horizontal col-sm-12 col-md-12 col-lg-10 ${styles.form} `} id="contactForm" onSubmit={submitForm} >

                            < div className="form-group" >
                                <label className="control-label col-sm-12" htmlFor="name">Name <span className={styles.mandate}>*</span></label>
                                < div className=" col-lg-12" >
                                    <input type="text" className="form-control" id="name" placeholder="Enter Name" name="name" required />
                                </div>
                            </div>

                            < div className="form-group" >
                                <label className="control-label col-sm-12" htmlFor="company">Company</label>
                                < div className=" col-lg-12" >
                                    <input type="text" className="form-control" id="company" placeholder="Enter Company" name="company" />
                                </div>
                            </div>

                            < div className="form-group" >
                                <label className="control-label col-sm-12" htmlFor="designation">Designation</label>
                                < div className="col-lg-12" >
                                    <input type="text" className="form-control" id="designation" placeholder="Enter Designation" name="designation" />
                                </div>
                            </div>

                            <div className="form-group">
                                <label className="control-label col-sm-12" htmlFor="email">Official Email <span className={styles.mandate}>*</span></label>
                                <div className="col-lg-12">
                                    <input type="email" className="form-control" id="email" placeholder="Enter Email" name="email" required onBlur={checkEmail} />
                                    <span id="emailErrorMsg"></span>
                                </div>
                            </div>

                            <div className="form-group">
                                <label className="control-label col-sm-12" htmlFor="contact">Phone Number</label>
                                <div className="col-lg-12">
                                    <input type="number" className="form-control" id="contactNumber" placeholder="Enter Phone Number" name="contact" />
                                    <span id="contactErrorMsg"></span>
                                </div>
                            </div>

                            <div className="form-group">
                                <div className="col-sm-offset-2 col-lg-12">
                                    <ReCAPTCHA
                                        ref={recaptchaRef}
                                        sitekey="6LfAmdYUAAAAAKlg8KhjjfjjZ2epI1E0tixGZJbC"
                                        render="explicit"
                                    />
                                </div>
                            </div>

                            <div className="form-group">
                                <div className="col-sm-offset-2 col-lg-12" >
                                    <input type="submit" className={`btn btn-primary ${styles.btnSubmit}`} value="Submit" />
                                </div>
                            </div>

                            < div className={`form-group ${styles.formgroup}`} >
                                < div className="col-sm-offset-2 col-lg-12" >
                                    <p id="message"></p>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

