import React, { useEffect } from "react";
import styles from "./LetsTalk.module.css";
import FormContent from "../components/formContent";
import Modal from "react-modal";
import '../styles/main.css';

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    maxWidth: '500px',
    zIndex: '5'
  },
};

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
//  Modal.setAppElement(document.getElementById("root"));piushadebnath@quantumphinance.com

function ModalDialog() {
  // var subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);
  useEffect(() => {
    const head = document.querySelector("head");
    const linkStyle = document.createElement("link");
    const script = document.createElement("script");
    script.setAttribute(
      "src",
      "https://assets.calendly.com/assets/external/widget.js"
    );
    linkStyle.setAttribute(
      "src",
      "https://assets.calendly.com/assets/external/widget.css"
    );
    head.appendChild(script);
    head.appendChild(linkStyle);


  });

  function openModal() {
    setIsOpen(true);
  }


  // function afterOpenModal() {
  //   // references are now sync'd and can be accessed.
  //   // subtitle.style.color = "#f00";

  // }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <div>
      <button
        className="btn btn-info"
        style={{ backgroundColor: "#0f96f2", margin: '0 15px' }}
        onClick={openModal}
      >
        Schedule an online demo
      </button>
      <Modal
        isOpen={modalIsOpen}
        // onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        id="calendlyModal"
        ariaHideApp={false}
      >
        <div
          text="Schedule an online demo"
          className="calendly-inline-widget col-sm-12 col-md-12 scheduleDemoDiv "
          data-url="https://calendly.com/quantumphinance/introduction-to-sheetkraft?primary_color=17e2ea"
          style={{ width: "100%", height: 'calc(100vh - 180px)', padding: "0", border: '1px solid #1e4a8d', position: 'relative' }}
        />
        <button onClick={closeModal} style={{ fontWeight: 'bold', position: 'absolute', right: '35px', top: '15px', background: 'none', outline: '0', border: '0', color: 'red' }}>x</button>
      </Modal>
    </div>
  );
}

// const Calendly = () => {
//   const [showCalendly, setshowCalendly] = useState(false);
//   useEffect(() => {
//     const head = document.querySelector("head");
//     const linkStyle = document.createElement("link");
//     const script = document.createElement("script");
//     script.setAttribute(
//       "src",
//       "https://assets.calendly.com/assets/external/widget.js"
//     );
//     linkStyle.setAttribute(
//       "src",
//       "https://assets.calendly.com/assets/external/widget.css"
//     );
//     head.appendChild(script);
//     head.appendChild(linkStyle);
//   });

//   return (
//     <div
//       className="col-sm-12 col-md-12"
//       onClick={() => setshowCalendly((showCalendly) => !showCalendly)}
//     >
//       {showCalendly ? (
//         <div style={{ position: "relative", background: "#ffffff" }}>
//           {/* <span
//             style={{
//               position: "absolute",
//               left: "10px",
//               top: "10px",
//               color: "#1e498c"
//             }}
//           >
//             Please Wait...
//           </span> */}
//           <div
//             text="Schedule an online demo"
//             className="calendly-inline-widget col-sm-12 col-md-12 scheduleDemoDiv "
//             data-url="https://calendly.com/sheetkraft/introduction-to-sheetkraft?primary_color=17e2ea"
//             style={{ width: "100%", height: "245px", padding: "0" }}
//           />
//         </div>
//       ) : (
//         <button
//           className="btn btn-default scheduleDemoBtn"
//           style={{ background: "#0f96f2", color: "#ffffff" }}
//         >
//           Schedule an online demo
//         </button>
//       )}
//     </div>
//   );
// };

export default function LetsTalk() {
  return (
    <div>
      <section className="section bg-sec-blue " id="contact" data-spy="scroll">
        <div className="section-header color-white text-center">
          <h3 className="text-capitalize color-white "> Let's talk</h3>
        </div>
        <div className="container container-fluid color-white">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-6 ">
              <div className="col-sm-12 col-md-12 ">
                <h4 className={`color-ter-blue `} id="addressBookIcon">
                  Address
                </h4>
                <p>
                  7<sup>th</sup> floor, Unit No. 715, C Wing, <br /> Kailas
                  Business Park, S. Veer Savarkar Marg, <br /> Park Site,
                  Vikhroli (W), Mumbai-400079.
                </p>
              </div>
              {/* <div>&nbsp;</div>
              <div className="col-sm-12 col-md-12 ">
                <h4 className={`color-ter-blue  `} id="phoneIcon">
                  Phone
                </h4>
                <p>+91 22 62362043 </p>
              </div> */}
              <div>&nbsp;</div>
              <div className="col-sm-12 col-md-12 ">
                <h4 className={`color-ter-blue `} id="messageIcon">
                  For General Queries & Technical Support
                </h4>
                <p>
                  <a
                    href="mailto:contact@quantumphinance.com"
                    className={styles.color}
                  >
                    contactus@quantumphinance.com
                  </a>
                </p>
              </div>
              <div>&nbsp;</div>
              <div className="col-sm-12 col-md-12 ">
                <h4 className={`color-ter-blue  `} id="salesIcon">
                  For Sale and Demos
                </h4>
                <p>
                  <a
                    href="mailto:sales@quantumphinance.com"
                    className={styles.color}
                  >
                    sales@quantumphinance.com
                  </a>
                </p>
              </div>
              <div>&nbsp;</div>
              <div>
                {/* <Calendly /> */}
                <ModalDialog />
              </div>
              <div>&nbsp;</div>
            </div>

            {/******form****/}
            <div className="col-sm-12 col-md-12 col-lg-6 ">
              <FormContent></FormContent>
            </div>
            {/******form****/}
          </div>
        </div>
      </section>
    </div>
  );
}
